<template>
    <Splide ref="splide" @splide:moved="onMoved" class="wrapper" :options="yourOptions">
        <SplideSlide v-for="(n, index) in arrayDates" :key="index">
            <div>
                <div class="current-month">{{ monthDate[index] }}</div>
                <div class="dates-grid">
                    <div class="title mobile-none">
                        <div>Monday</div>
                        <div>Tuesday</div>
                        <div>Wednesday</div>
                        <div>Thursday</div>
                        <div>Friday</div>
                        <div class="weekend">Saturday</div>
                        <div class="weekend">Sunday</div>
                    </div>
                    <div class="title mobile">
                        <div>Mo</div>
                        <div>Tu</div>
                        <div>We</div>
                        <div>Th</div>
                        <div>Fr</div>
                        <div class="weekend">Sa</div>
                        <div class="weekend">Su</div>
                    </div>
                    <div class="days">
                        <div v-for="(item, subIndex) in n" :key="subIndex"
                            :class="[{ 'active': item.status === 'active' }, { 'inactive': item.status === 'inactive' }, { 'focus': item.status === 'focus' }, { 'half-focus': item.status === 'half-focus' }, { 'past': item.status === 'past' }]"
                            @click="item.status !== 'inactive' && item.status !== 'past' ? clickEvent(item.value) : null">
                            {{ getDays(item.value) }}
                        </div>
                    </div>
                </div>
            </div>
        </SplideSlide>
    </Splide>
</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css/skyblue';
import '@splidejs/vue-splide/css/sea-green';
import '@splidejs/vue-splide/css/core';
import '@splidejs/vue-splide/css';
import { ref, onMounted } from 'vue';
export default {
    name: 'picker-date',
    components: { Splide, SplideSlide },
    props: {
        type: Number,
        allYear: Boolean,
        value_start: Object,
        value_end: String,
    },
    data() {
        return {
            step: false,
            currentIndex: 1,
            yourOptions: {
                direction: 'ttb',
                height: '746.5px',
                wheel: true,
                releaseWheel: true,
                arrows: false,
                perMove: 1,
                speed: 200,
                pagination: false,
                breakpoints: {
                    768: {
                        height: '600px',
                        autoHeight: true,
                        trimSpace: false,
                        perMove: 1,
                        gap: '10px',
                    },
                }
            },
            conditionAlreadyMet: false,
            allSelectMonth: false,
            arrayDates: [],
            newArrayDates: [],
            monthDate: [],
            current_date: new Date(),
            next_date: '',
            prev_date: '',
            current_start_date: '_._.__',
            current_end_date: '_._.__',
            range: false,
            current_select_date: [],
            current_select_date_query: [],
            current_select_date_allMonth: [],
            current_select_rangeAllMonth: {
                current_start: null,
                current_end: null,
            },
            current_select_range: {
                current_start: null,
                current_end: null,
            },
            current_select_query: {
                current_start: null,
                current_end: null,
            }
        }
    },
    methods: {
        getLastDayOfMonth(year, month) {
            return new Date(year, month + 1, 0).getDate();
        },
        formatDate(date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const day = String(date.getDate()).padStart(2, "0");
            return `${year}-${month}-${day}`;
        },
        changeData() {
            const currentDate = new Date().getTime();
            const currentMonth = new Date().getMonth();
            const currentYear = new Date().getFullYear();
            if (this.type === 1) {
                if (this.current_select_date.length > 0) this.allSelectMonth = true;
                else this.allSelectMonth = false;
                this.arrayDates.forEach(innerArray => {
                innerArray.forEach(element => {
                        const elementDate = new Date(element.value);
                        const isCurrentMonth = elementDate.getMonth() === currentMonth && elementDate.getFullYear() === currentYear;

                        if (element.value < currentDate && isCurrentMonth) {
                            element.status = 'past'; // Прошедшие даты в текущем месяце
                        } else if (!isCurrentMonth) {
                            element.status = 'inactive'; // Даты за пределами текущего месяца
                        } else if (this.current_select_date.includes(element.value) && element.status !== 'inactive') {
                            element.status = "focus"; // Выбранные даты
                        } else if (!this.current_select_date.includes(element.value) && element.status === "focus") {
                            element.status = "active"; // Активные, но не выбранные даты
                        }
                    });
                });
            } else if (this.type === 2) {
    this.allSelectMonth = false;

    const currentMonth = new Date().getMonth(); // Текущий месяц
    const currentYear = new Date().getFullYear(); // Текущий год

    this.arrayDates.forEach(innerArray => {
        innerArray.forEach(element => {
            const elementDate = new Date(element.value);
            const isCurrentMonth = elementDate.getMonth() === currentMonth && elementDate.getFullYear() === currentYear;

            if (!isCurrentMonth) {
                element.status = 'inactive'; // Даты за пределами текущего месяца
            } else if (element.value < currentDate) {
                element.status = 'past'; // Прошедшие даты в текущем месяце
            } else if (
                (this.current_select_range.current_start > element.value &&
                    (element.status === "focus" || element.status === "half-focus")) ||
                (this.current_select_range.current_end < element.value &&
                    (element.status === "focus" || element.status === "half-focus"))
            ) {
                element.status = "active"; // Активные, но не входящие в диапазон
            }

            if (
                (element.value === this.current_select_range.current_start && element.status !== 'inactive') ||
                (this.current_select_range.current_end === element.value && element.status !== 'inactive')
            ) {
                element.status = "focus"; // Границы выбранного диапазона
            } else if (
                this.current_select_range.current_start < element.value &&
                this.current_select_range.current_end > element.value &&
                element.status === 'active'
            ) {
                element.status = 'half-focus'; // Даты внутри выбранного диапазона
            }
        });

        const firstElement = innerArray.find(element => element.status !== 'inactive');
        let lastInactiveElement = null;

        for (let i = innerArray.length - 1; i >= 0; i--) {
            const element = innerArray[i];
            if (element.status !== 'inactive') {
                lastInactiveElement = element.value;
                break;
            }
        }

        if (
            firstElement &&
            this.current_select_range.current_start === firstElement.value &&
            this.current_select_range.current_end === lastInactiveElement
        ) {
            this.allSelectMonth = true;
        }
    });
}
        },
        onMoved(splide, newIndex, prevIndex) {
            if (newIndex === splide.length - 2 || newIndex === splide.length - 1) {
                if (this.arrayDates.length == 12) {
                    this.arrayDates.splice(0, 1);
                    this.monthDate.splice(0, 1);
                    this.prev_date.setMonth(this.prev_date.getMonth() + 1);
                }
                this.next_date.setMonth(this.next_date.getMonth() + 1);
                this.addMonth(new Date(this.next_date));

                if (this.arrayDates.length == 12) splide.go(9);
            } else if (newIndex === 1 && this.arrayDates.length == 12 || prevIndex === 1 && this.arrayDates.length == 12) {
                if (this.prev_date.getTime() != this.current_date.getTime()) {
                    this.arrayDates.splice(11, 1);
                    this.monthDate.splice(11, 1);
                    this.prev_date.setMonth(this.prev_date.getMonth() - 1);
                    this.next_date.setMonth(this.next_date.getMonth() - 1);
                    this.addMonth(new Date(this.prev_date), '-');
                    splide.go(1);
                }

            }

        },
        addMonth(date, type) {
    this.newArrayDates = [];
    const currentDate = new Date().getTime();
    let start_date = new Date(date);
    let month = start_date.getMonth();

    start_date.setDate(1);
    start_date.setHours(0, 0, 0, 0);
    let month1 = this.$store.state.months_names[start_date.getMonth()] + ' ' + start_date.getFullYear();
    let day_week = start_date.getDay() === 0 ? 6 : start_date.getDay() - 1;
    let last_date = new Date(start_date.getFullYear(), start_date.getMonth() + 1, 0);
    let day_week_next = 7 - last_date.getDay();
    let last_day_prev_month = new Date(start_date.getFullYear(), start_date.getMonth(), -day_week + 1);
    let first_day_next_month = new Date(start_date.getFullYear(), start_date.getMonth() + 1, 1);

    for (let i = 0; i < day_week; i++) {
        let current_time = last_day_prev_month.getTime();
        let object = { value: current_time, status: 'inactive' };
        this.newArrayDates.push(object);
        last_day_prev_month.setDate(last_day_prev_month.getDate() + 1);
    }

    if (this.type === 1) {
        while (true) {
            if (start_date.getMonth() !== month) break;
            let current_time = start_date.getTime();
            let object = { value: current_time, status: 'active' };

            if (current_time < currentDate) {
                object.status = 'past';
            } else {
                for (let i = 0; i < this.current_select_date.length; i++) {
                    if (this.current_select_date[i] === current_time) {
                        object.status = 'focus';
                        break;
                    }
                }
            }
            this.newArrayDates.push(object);
            start_date.setDate(start_date.getDate() + 1);
        }
    } else if (this.type === 2) {
        while (true) {
            if (start_date.getMonth() !== month) break;
            let current_time = start_date.getTime();
            let object = { value: current_time, status: 'active' };

            if (current_time < currentDate) {
                object.status = 'past';
            } else {
                if (this.current_select_range.current_start === current_time || this.current_select_range.current_end === current_time) {
                    object.status = 'focus';
                } else if (this.current_select_range.current_start < current_time && this.current_select_range.current_end > current_time) {
                    object.status = 'half-focus';
                }
            }
            this.newArrayDates.push(object);
            start_date.setDate(start_date.getDate() + 1);
        }
    }

    for (let i = 0; i < day_week_next; i++) {
        if (day_week_next === 7) break;
        let current_time = first_day_next_month.getTime();
        let object = { value: current_time, status: 'inactive' };
        this.newArrayDates.push(object);
        first_day_next_month.setDate(first_day_next_month.getDate() + 1);
    }

    if (type === '-') {
        this.monthDate.unshift(month1);
        this.arrayDates = [this.newArrayDates, ...this.arrayDates];
    } else {
        this.monthDate.push(month1);
        this.arrayDates.push(this.newArrayDates);
    }
},
    clickEvent(value) {
                if (this.type === 1) {
                    if (this.current_select_date.indexOf(value) != -1) {
                        let current_start_date = new Date(value);
                        let index = this.current_select_date.indexOf(value);
                        this.current_select_date.splice(index, 1);
                        let index2 = this.current_select_date.indexOf(current_start_date.getFullYear() + '-' + ('0' + (current_start_date.getMonth() + 1)).slice(-2) + '-' + ('0' + (current_start_date.getDate())).slice(-2));
                        this.current_select_date_query.splice(index2, 1);
                        this.changeData();
                    } else {
                        let current_start_date = new Date(value);
                        this.current_select_date_query.push(current_start_date.getFullYear() + '-' + ('0' + (current_start_date.getMonth() + 1)).slice(-2) + '-' + ('0' + (current_start_date.getDate())).slice(-2));
                        this.current_select_date.push(value);
                        this.changeData();
                    }
                }
                else if (this.type === 2) {
                    if (this.current_select_range.current_start > value || !this.current_select_range.current_start) {
                        this.current_select_range.current_start = value;
                        let current_start_date = new Date(value);
                        this.current_start_date = this.$store.state.short_months_names[current_start_date.getMonth()] + ' ' + current_start_date.getDate() + ', ' + current_start_date.getFullYear();
                        this.current_select_query.current_start = current_start_date.getFullYear() + '-' + ('0' + (current_start_date.getMonth() + 1)).slice(-2) + '-' + ('0' + (current_start_date.getDate())).slice(-2);
                        this.range = true;
                    } else if (value > this.current_select_range.current_end) {
                        this.current_select_range.current_end = value;
                        let current_end_date = new Date(value);
                        this.current_end_date = this.$store.state.short_months_names[current_end_date.getMonth()] + ' ' + current_end_date.getDate() + ', ' + current_end_date.getFullYear();
                        this.current_select_query.current_end = current_end_date.getFullYear() + '-' + ('0' + (current_end_date.getMonth() + 1)).slice(-2) + '-' + ('0' + (current_end_date.getDate())).slice(-2);
                        this.range = false;
                    } else {
                        if (this.range === false) {
                            this.current_select_range.current_start = value;
                            let current_start_date = new Date(value);
                            this.current_start_date = this.$store.state.short_months_names[current_start_date.getMonth()] + ' ' + current_start_date.getDate() + ', ' + current_start_date.getFullYear();
                            this.current_select_query.current_start = current_start_date.getFullYear() + '-' + ('0' + (current_start_date.getMonth() + 1)).slice(-2) + '-' + ('0' + (current_start_date.getDate())).slice(-2);
                            this.range = true;
                        } else {
                            this.current_select_range.current_end = value;
                            let current_end_date = new Date(value);
                            this.current_end_date = this.$store.state.short_months_names[current_end_date.getMonth()] + ' ' + current_end_date.getDate() + ', ' + current_end_date.getFullYear();
                            this.current_select_query.current_end = current_end_date.getFullYear() + '-' + ('0' + (current_end_date.getMonth() + 1)).slice(-2) + '-' + ('0' + (current_end_date.getDate())).slice(-2);
                            this.range = false;
                        }

                    }
                    this.changeData();
                }
            },
        getDays(value) {
            let new_value = new Date(value);
            return new_value.getDate();
        },
        getCurrentMonth() {
            this.current_date.setDate(1);
            return this.$store.state.months_names[this.current_date.getMonth()] + ' ' + this.current_date.getFullYear();
        },
        getPrevMonth() {
            this.prev_date = new Date(this.current_date);
            this.prev_date.setDate(1);
        },
        getNextMonth() {
            this.next_date = new Date(this.current_date);
            this.next_date.setDate(1);
            this.next_date.setMonth(this.next_date.getMonth() + 1);
            return this.$store.state.months_names[this.next_date.getMonth()] + ' ' + this.next_date.getFullYear();
        },
        updateMonth() {
            this.arrayDates = [];
            this.addMonth(new Date(this.current_date));
            this.addMonth(new Date(this.next_date));
            this.next_date.setMonth(this.next_date.getMonth() + 1);
            this.addMonth(new Date(this.next_date));
        },
    },
    mounted() {
        this.getCurrentMonth();
        this.getNextMonth();
        this.getPrevMonth();
        this.updateMonth();
    },
    watch: {
        type: {
            handler(newValue, oldValue) {
                this.range = false;
                this.allSelectMonth = false;
                this.current_select_date = [];
                this.current_select_date_query = [];
                this.current_select_range.current_start = null;
                this.current_select_range.current_end = null;
                this.current_select_query.current_start = null;
                this.current_select_query.current_end = null;
                this.current_start_date = '_._.__';
                this.current_end_date = '_._.__';
                this.arrayDates.forEach(innerArray => {
                    innerArray.forEach(element => {
                        if (element.status === 'focus' || element.status === 'half-focus') {
                            element.status = "active";
                        }
                    });
                });
            },
        },
        allSelectMonth: {
            handler(val, oldVal) {
                this.$emit('allMonth', this.allSelectMonth);
            },
            deep: true
        },
        allYear(val, oldVal) {
            this.current_select_date_allMonth = { ...this.current_select_date_query };
            this.current_select_rangeAllMonth = { ...this.current_select_query };
            if (this.type == 1) {
                if (this.allYear) {
                    const currentDate = new Date();
                    const currentYear = currentDate.getFullYear();
                    const lastMonthOfYear = 11;
                    this.current_select_date.forEach((selectedDate) => {
                        const date = new Date(selectedDate);
                        const selectedMonth = date.getMonth();

                        for (let month = selectedMonth + 1; month <= lastMonthOfYear; month++) {
                            const lastDayOfMonth = this.getLastDayOfMonth(currentYear, month);
                            console.log(lastDayOfMonth);
                            const selectedDay = date.getDate();
                            console.log(selectedDay);
                            const newYear = month > lastMonthOfYear ? currentYear + 1 : currentYear;
                            if (selectedDay <= lastDayOfMonth) {
                                const newDate = new Date(currentYear, month, date.getDate());
                                const formattedDate = this.formatDate(newDate);
                                if (!this.current_select_date_allMonth.includes(formattedDate)) {
                                    this.current_select_date_allMonth.push(formattedDate);
                                }
                            }
                        }
                    });
                    this.$emit('update:modelValue', this.current_select_date_allMonth);
                }
                else this.$emit('update:modelValue', this.current_select_date_query);
            } else {
                if (this.allYear) {

                    const currentDate = new Date();
                    const currentYear = currentDate.getFullYear();
                    const lastDayOfYear = new Date(currentYear, 11, 31);
                    const formattedDate = this.formatDate(lastDayOfYear)
                    this.current_select_rangeAllMonth.current_start = this.current_select_query.current_start;
                    this.current_select_rangeAllMonth.current_end = formattedDate;
                    this.$emit('update:modelValue', this.current_select_rangeAllMonth);
                }
                else this.$emit('update:modelValue', this.current_select_query);
            }
        },
        current_select_date_query: {
            handler(val, oldVal) {
                this.current_select_date_allMonth = [...this.current_select_date_query];
                if (this.allYear) {
                    const currentDate = new Date();
                    const currentYear = currentDate.getFullYear();
                    const lastMonthOfYear = 11;
                    console.log(currentYear);
                    this.current_select_date.forEach((selectedDate) => {
                        const date = new Date(selectedDate);
                        const selectedMonth = date.getMonth();

                        for (let month = selectedMonth + 1; month <= lastMonthOfYear; month++) {
                            const lastDayOfMonth = this.getLastDayOfMonth(currentYear, month);
                            console.log(lastDayOfMonth);
                            const selectedDay = date.getDate();
                            console.log(selectedDay);
                            const newYear = month > lastMonthOfYear ? currentYear + 1 : currentYear;
                            if (selectedDay <= lastDayOfMonth) {
                                const newDate = new Date(currentYear, month, date.getDate());
                                const formattedDate = this.formatDate(newDate);
                                if (!this.current_select_date_allMonth.includes(formattedDate)) {
                                    this.current_select_date_allMonth.push(formattedDate);
                                }
                            }
                        }
                    });
                    this.$emit('update:modelValue', this.current_select_date_allMonth);
                }
                else this.$emit('update:modelValue', this.current_select_date_query);
            },
            deep: true
        },
        current_select_query: {
            handler(val, oldVal) {
                if (this.allYear) {

                    const currentDate = new Date();
                    const currentYear = currentDate.getFullYear();
                    const lastDayOfYear = new Date(currentYear, 11, 31);
                    const formattedDate = this.formatDate(lastDayOfYear)
                    this.current_select_rangeAllMonth.current_start = this.current_select_query.current_start;
                    this.current_select_rangeAllMonth.current_end = formattedDate;
                    this.$emit('update:modelValue', this.current_select_rangeAllMonth);
                }
                else this.$emit('update:modelValue', this.current_select_query);
            },
            deep: true
        },
        value_start() {
            if (this.value_end == null) {
                this.current_select_date_query = [...this.value_start];
                this.current_select_date_allMonth = [...this.value_start];

                const dateObjects = this.value_start.map(dateString => {
                    const date = new Date(dateString);
                    date.setHours(0, 0, 0, 0); // Устанавливаем время на полночь
                    return date;
                });
                console.log(dateObjects);
                const timeInMilliseconds = dateObjects.map(dateObject => dateObject.getTime());
                this.current_select_date = [...timeInMilliseconds];
                this.changeData();
            }
        },
        value_end() {
            if (this.value_end != null) {
                this.current_select_query.current_start = this.value_start;
                this.current_select_query.current_end = this.value_end;

                let dateObjects = new Date(this.value_start);
                dateObjects.setHours(0, 0, 0, 0);
                let timeInMilliseconds = dateObjects.getTime();
                this.current_select_range.current_start = timeInMilliseconds;

                dateObjects = new Date(this.value_end);
                dateObjects.setHours(0, 0, 0, 0); // Устанавливаем время на полночь
                timeInMilliseconds = dateObjects.getTime();
                this.current_select_range.current_end = timeInMilliseconds;
                this.changeData();
            }
        },
    },
}
</script>

<style scoped>
.dates-grid .days>div.past {
    border-radius: 6px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    background: #FFF;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
    opacity: 0.4 ;
}
#other_month {
    display: none;
}

.mobile {
    display: none !important;
}

label {
    color: var(--Black-400, #292929);
    text-align: center;
    font-family: Standerd;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 125% */
    display: flex;
    gap: 8px;

    margin-top: 24px;

    display: inline-flex;
    align-items: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

label::before {
    content: '';
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 4px;
    border: 2px solid #E5E5EA;
    width: 20px;
    height: 20px;
    transition: 0.3s;
    background-image: url("@/assets/image/check.svg");
}

label:hover::before {
    border-radius: 4px;
    border: 2px solid #606060;
}

#other_month:checked+label::before {
    border-color: #292929;
    background-color: #292929;

}

.wrapper {
    padding: 24px;
    border-radius: 16px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    background: var(--White-100, #FFF);
}


.current-month {
    color: var(--Black-300, #333);
    font-family: Standerd;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.144px;
    padding-bottom: 21px;
}

.dates-grid {
    width: 100%;
    margin-bottom: 24px;
}

.dates-grid .title {
    color: var(--Black-400, #292929);
    text-align: center;
    font-family: Standerd;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 100% */
    display: flex;
    justify-content: space-between;
    gap: 7.5px;
    margin-bottom: 12px;
}

.dates-grid .title>div {
    max-width: 127px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc((100% / 7) - 6.5px);
}

.dates-grid .days {
    display: flex;
    flex-wrap: wrap;
    gap: 7.5px;
}

.dates-grid .days>div {
    width: calc((100% / 7) - 6.5px) !important;
    color: var(--black-300, #333);
    height: 100px;
    text-align: end;
    color: var(--Gray-300, #888);
    font-family: Standerd;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    padding: 16px;
}

.dates-grid .days>div.inactive {
    color: #FFF;
}

.dates-grid .days>div.active {
    cursor: pointer;
    border-radius: 6px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    background: #FFF;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);

}

.dates-grid .days>div.focus {
    color: var(--White-100, #FFF);
    font-family: Standerd;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    border-radius: 6px;
    border: 1.5px solid var(--Black-300, #333);
    background: var(--Gray-400, #606060);

    /* 2 */
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
}

.dates-grid .days>div.half-focus {
    color: var(--White-100, #FFF);
    font-family: Standerd;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    border-radius: 6px;
    border: 1.5px solid var(--Black-300, #333);
    background: var(--Gray-400, #606060);

    /* 2 */
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
}

.dates-grid .days div.active:hover {
    color: var(--White-100, #FFF);
    font-family: Standerd;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    border-radius: 6px;
    border: 1.5px solid var(--Black-300, #333);
    background: var(--Gray-400, #606060);

    /* 2 */
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
}

@media screen and (max-width: 1300px) {
    .mobile {
        display: flex !important;
    }

    .mobile-none {
        display: none !important;
    }
}

@media screen and (max-width: 768px) {
    .current-month {
        font-size: 20px;
        text-align: center;
        padding-bottom: 12px;
    }

    .wrapper {
        padding: unset;
        border-radius: unset;
        border: unset;
        background: var(--White-100, #FFF);
    }

    .dates-grid .days[data-v-1d8e2435] {
        gap: 5.33px;
    }

    .dates-grid .days>div {
        width: calc((100% / 7) - 4.58px);
        color: var(--black-300, #333);
        height: 54px;
        text-align: end;
        color: var(--Gray-300, #888);
        font-family: Standerd;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        /* 142.857% */
        padding: 4px;
    }

    .dates-grid .title {
        gap: 5.33px;
    }

    .dates-grid .title>div {
        width: calc((100% / 7) - 4.58px);
    }

    .mobile {
        display: flex !important;
    }

    .mobile-none {
        display: none !important;
    }
}
</style>