c<template>
    <div class="wrapper-content">
        <div class="content" :class="{ show: step === 0 }">
            <input-wrapper>
                <div class="input-title">What kind of service do you provide?</div>
                <div class="input-text">Please select one of the options so that we can help you create the event correctly.
                </div>
                <radio-button v-model="v$.newEvent.type_event.$model" :value="newEventData.type_event" :array="serviceData"
                    :name="'service'"></radio-button>
                <error-field-text v-if="v$.newEvent.type_event.$invalid && submitted">Field not filled in</error-field-text>
            </input-wrapper>
            <input-wrapper>
                <div class="input-title">Will you be charged to attend your event</div>
                <div class="input-text">Please select one of the options so that we can help you create the event correctly.
                </div>
                <radio-button v-model="v$.newEvent.charged_to_attend.$model" :value="newEventData.charged_to_attend"
                    :array="paymentsData" :name="'payments'"></radio-button>
                <error-field-text v-if="v$.newEvent.charged_to_attend.$invalid && submitted">Field not filled in</error-field-text>
            </input-wrapper>
            <input-wrapper v-if="categoryData.length > 0">
                <div class="input-title">Select the categories that apply to your event</div>
                <div class="input-text">Please select the category that suits your service. The maximum number of categories
                    for one service is 3</div>
                <category-select @otherCategory="otherCategory" v-model="v$.newEvent.selectedCategory.$model"
                    :selected="newEventData.selectedCategory" :categorySearch="categoryDataSearch" :category="categoryData"
                    :name="'category'"></category-select>
                <error-field-text v-if="v$.newEvent.selectedCategory.$invalid && submitted">Field not filled in</error-field-text>
            </input-wrapper>
        </div>
        <div class="content" :class="{ show: step === 1 }">
            <input-wrapper>
                <div class="input-title">Event title</div>
                <div class="input-text">Please describe the name of your event</div>
                <input-text v-model="v$.newEvent.event_name.$model" :value="newEventData.event_name"
                    :placeholder="'Event name'"></input-text>
                <error-field-text v-if="v$.newEvent.event_name.$invalid && submitted">Field not filled in</error-field-text>
            </input-wrapper>
            <input-wrapper>
                <div class="input-title">Event description</div>
                <div class="input-text">Please describe in detail what will be in your event</div>
                <input-text-area v-model="v$.newEvent.overview.$model" :value="newEventData.overview"
                    :placeholder="'Description'"></input-text-area>
                <error-field-text v-if="v$.newEvent.overview.$invalid && submitted">Field not filled in</error-field-text>
            </input-wrapper>
            <input-wrapper>
                <div class="input-title">Describe the services included in your event</div>
                <div class="input-text">Please describe the services that are included in your event and for which you need
                    to pay extra separately</div>
                <div class="flex-wrapper">
                    <div>
                        <div class="title">What's Included</div>
                        <add-list v-model="v$.newEvent.included.$model" :value="newEventData.included"></add-list>
                    </div>
                    <div>
                        <div class="title">What's Not Included</div>
                        <add-list v-model="v$.newEvent.not_included.$model" :value="newEventData.not_included"></add-list>
                    </div>
                </div>
                <error-field-text v-if="(v$.newEvent.included.$invalid || v$.newEvent.not_included.$invalid) && submitted">Field not filled in</error-field-text>
            </input-wrapper>
            <input-wrapper>
                <div class="input-title">Additional information</div>
                <div class="input-text">Specify additional information about your event that may be important to the
                    customer</div>
                <add-list v-model="v$.newEvent.additional_information.$model"
                    :value="newEventData.additional_information"></add-list>
                <error-field-text v-if="v$.newEvent.additional_information.$invalid && submitted">Field not filled in</error-field-text>
            </input-wrapper>
            <input-wrapper>
                <div class="input-title">Location</div>
                <div class="input-text">Locations you will visit at the event </div>
                <add-list v-model="v$.newEvent.location.$model"
                    :value="newEventData.location" :type="2"></add-list>
                <error-field-text v-if="v$.newEvent.location.$invalid && submitted">Field not filled in</error-field-text>
            </input-wrapper>
            <input-wrapper>
                <div class="input-title">Meeting point</div>
                <div class="input-text">Please select one of the options for how you will meet customers</div>
                <div class="wrapper-column">
                    <radio-button v-model="v$.newEvent.meeting_point_type.$model" :value="newEventData.meeting_point_type"
                        :array="meeting_point" :name="'meeting'"></radio-button>
                    <LocationWrapperBox v-if="newEvent.meeting_point_type === 2">
                        <InputTextCountry
                            class="half-width" 
                            :placeholder="'Country'" 
                            :value="newEventData.meeting_point_country"
                            v-model="v$.newEvent.meeting_point_country.$model">
                        </InputTextCountry>
                        <InputTextCity
                            class="half-width" 
                            :placeholder="'City'" 
                            :country="v$.newEvent.meeting_point_country.$model"
                            :value="newEventData.meeting_point_city"
                            :country_value="newEventData.meeting_point_country"
                            v-model="v$.newEvent.meeting_point_city.$model">
                        </InputTextCity>
                        <input-text class="full-width" v-model="v$.newEvent.meeting_point_address.$model" :value="newEventData.meeting_point_address" :placeholder="'Address'"></input-text>
                        <input-text class="low-width" v-model="v$.newEvent.meeting_point_building_number.$model" :value="newEventData.meeting_point_building_number" :placeholder="'Building number'"></input-text>

                    </LocationWrapperBox>
                </div>
                <error-field-text v-if="meetingPointError && submitted">Field not filled in</error-field-text>
            </input-wrapper>
            <input-wrapper>
                <div class="input-title">Cancellation Policy</div>
                <div class="input-text">Please indicate what are the cancellation conditions and penalties</div>
                <add-list v-model="v$.newEvent.cancelation_policy.$model"
                    :value="newEventData.cancelation_policy"></add-list>
                <error-field-text v-if="v$.newEvent.cancelation_policy.$invalid && submitted">Field not filled in</error-field-text>
            </input-wrapper>
        </div>
        <div class="content" :class="{ show: step === 2 }">
            <input-wrapper>
                <div class="input-title">Add photo</div>
                <div class="input-text">The first photo will be on the cover of the ad. Drag to reorder.</div>
                <images-load @files="uploadFiles" @files_url="uploadFilesUrl" :event_id="newEventData.id" :value="newEventData.images"></images-load>
                <error-field-text v-if="v$.newEvent.images.$invalid && submitted">Field not filled in</error-field-text>
            </input-wrapper>
            <input-wrapper v-if="newEvent.charged_to_attend === 2">
                <div class="input-title">Price</div>
                <div class="input-text">Please indicate how much your service will cost</div>
                <div class="price-wrapper">
                    <div>
                        <div class="title">Currency</div>
                        <DropSelect v-model="v$.newEvent.currency_id.$model" :value="newEventData.currency_id"
                            :object="currencyData"></DropSelect>
                    </div>
                    <div>
                        <div class="title">Price</div>
                        <input-text v-model="v$.newEvent.price.$model" :placeholder="'price'"
                            :value="newEventData.price"></input-text>
                    </div>
                    <div>
                        <div class="title">Conditions</div>
                        <DropSelect v-model="v$.newEvent.conditions.$model" :value="newEventData.conditions"
                            :object="conditionsData"></DropSelect>
                    </div>
                </div>
                <error-field-text
                    v-if="v$.newEvent.currency_id.$invalid && submitted || v$.newEvent.price.$invalid && submitted || v$.newEvent.conditions.$invalid && submitted">Field not filled in</error-field-text>
            </input-wrapper>
        </div>
        <div class="content max-width" :class="{ show: step === 3 }">
            <input-wrapper>
                <div class="input-title">When are you planning to hold the event?</div>
                <div class="input-text">Specify the days or period in which you will hold the event</div>
                <div class="picker-option">
                    <button :class="{ 'active': datePickerType == 1 }" @click="datePickerType = 1" type="button">
                        Day
                    </button>
                    <div class="arrow__top"></div>
                    <button type="button" :class="{ 'active': datePickerType == 2 }" @click="datePickerType = 2">
                        Period
                    </button>

                </div>
                <PickerDate v-model="v$.newEvent.datePicker.$model" :type="datePickerType" :allYear="allSelectMonthCheck"
                    :value_start="newEventData.start_date" :value_end="newEventData.end_date"
                    @allMonth="$event == false ? (allSelectMonthCheck = false, allSelectMonthCheckRight = $event) : allSelectMonthCheckRight = $event">
                </PickerDate>
                <error-field-text v-if="v$.newEvent.datePicker.$invalid && submitted">Field not filled in</error-field-text>
            </input-wrapper>
        </div>
        <div class="content max-width" :class="{ show: step === 4 }">
            <input-wrapper>
                <div class="input-title">Choose time</div>
                <div class="input-text">Specify the start time of your event</div>
                <ChoiseTime v-model="v$.newEvent.choose_time.$model" :times="timeData" :selected="newEventData.choose_time"
                    :name="'time'"></ChoiseTime>
                <error-field-text v-if="v$.newEvent.choose_time.$invalid && submitted">Field not filled in</error-field-text>
            </input-wrapper>
        </div>
        <div class="content max-width" :class="{ show: step === 5 }">
            <input-wrapper>
                <div class="input-title">Any restrictions in regards of your audience?</div>
                <div class="input-text">Specify restrictions if any</div>
                <radio-button class="w-100" v-model="v$.newEvent.age_restrictions.$model"
                    :value="newEventData.age_restrictions" :array="ageRestrictions"
                    :name="'age-restrictions'"></radio-button>
                <error-field-text v-if="v$.newEvent.age_restrictions.$invalid && submitted">Field not filled in</error-field-text>
            </input-wrapper>
            <input-wrapper>
                <div class="input-title">Number of people</div>
                <div class="input-text">Specify the maximum number of people who can take part in the event</div>
                <number-picker v-model="v$.newEvent.max_participants.$model"
                    :value="newEventData.max_participants"></number-picker>
                <error-field-text v-if="v$.newEvent.max_participants.$invalid && submitted">Field not filled in</error-field-text>
            </input-wrapper>
            <input-wrapper>
                <div class="input-title">Select confirmation type</div>
                <div class="input-text">Specify payment methods</div>
                <radio-button class="w-100" v-model="v$.newEvent.confirmation_type.$model"
                    :value="newEventData.confirmation_type" :array="confirmationType"
                    :name="'confirmationtype'"></radio-button>
                <error-field-text v-if="v$.newEvent.confirmation_type.$invalid && submitted">Field not filled in</error-field-text>
            </input-wrapper>
            <input-wrapper>
                <div class="input-title">Payment method</div>
                <div class="input-text">Choose the option how you want your applications to be confirmed on the platform
                </div>
                <radio-button class="w-100" v-model="v$.newEvent.payment_method.$model" :value="newEventData.payment_method"
                    :array="paymentMethodData" :name="'payment-method'"></radio-button>
                <error-field-text v-if="v$.newEvent.payment_method.$invalid && submitted">Field not filled in</error-field-text>
            </input-wrapper>
            <input-wrapper>
                <div class="input-title">Live tour guide</div>
                <div class="input-text">Specify what language the guide will speak</div>
                <radio-button class="w-100" v-model="v$.newEvent.live_tour_guide.$model"
                    :value="newEventData.live_tour_guide" :array="languegesData" :name="'live-tour-guide'"></radio-button>
                <error-field-text v-if="v$.newEvent.live_tour_guide.$invalid && submitted">Field not filled in</error-field-text>
            </input-wrapper>
            <input-wrapper>
                <div class="input-title">Event duration</div>
                <div class="input-text">Please indicate the duration of the event</div>
                <div class="duratio-wrapper">
                    <div class="time">
                        <div class="title">Time</div>
                        <input-text v-model="v$.newEvent.estimated_duration.$model" :value="newEventData.estimated_duration"
                            :placeholder="'Time'"></input-text>
                    </div>
                    <div class="meaning">
                        <div class="title">Meaning</div>
                        <DropSelect v-model="v$.newEvent.estimated_duration_type.$model"
                            :value="newEvent.estimated_duration_type" :object="mapeaningData">
                        </DropSelect>
                    </div>
                </div>
                <error-field-text v-if="v$.newEvent.estimated_duration.$invalid && submitted">Field not filled in</error-field-text>
            </input-wrapper>
        </div>
        <div class="steps">
            <div class="progress">
                <div :class="{ 'current': step === 0, 'active': step > 0 }"></div>
                <div :class="{ 'current': step === 1, 'active': step > 1 }"></div>
                <div :class="{ 'current': step === 2, 'active': step > 2 }"></div>
                <div :class="{ 'current': step === 3, 'active': step > 3 }"></div>
                <div :class="{ 'current': step === 4, 'active': step > 4 }"></div>
                <div :class="{ 'current': step === 5, 'active': step > 5 }"></div>
            </div>
            <input class="all-month-check" v-model="allSelectMonthCheck" id="other_month" type="checkbox">
            <label class="all-month" :class="{ 'active': allSelectMonthCheckRight === true && step === 3 }"
                for="other_month">
                Apply same to other months
            </label>
            <div class="button-wrapper">
                <div v-if="step == 0"></div>
                <div class="left" v-if="step != 0" @click="previousStep">
                    <div>Back</div>
                </div>
                <div class="right">
                    <div class="mobile-none" v-if="error_data.length == 0 && step>0 && step != 3" @click="saveDraft">Save</div>
                    <div class="next" v-if="step < 5 && error_data.length == 0" @click="nextStep">Next</div>
                    <div class="next" v-if="step === 5 && error_data.length == 0" @click="nextPreview">Preview</div>
                    <div class="mobile-none" v-if="error_data.length > 0 && step>0 && step!=3" @click="saveDraft">Save as Draft </div>
                    <div class="next" v-if="error_data.length > 0" @click="nextFinish">Finish</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import axios from "axios";
import NumberPicker from "@/components/Busines/EventDraft/NumberPicker.vue";
import ErrorFieldText from "@/components/UI/Busines/ErrorFieldText.vue";
import PickerDate from "@/components/Busines/EventDraft/PickerDate.vue";
import InputText from "@/components/Busines/EventDraft/InputText.vue";
import InputTextAddress from "@/components/Busines/EventDraft/InputTextAddress.vue";
import DropSelect from "@/components/Busines/EventDraft/Select.vue";
import ImagesLoad from "@/components/Busines/EventDraft/ImagesLoad.vue";
import AddList from "@/components/Busines/EventDraft/AddList.vue";
import InputTextArea from "@/components/Busines/EventDraft/InputTextArea.vue";
import RadioButton from "@/components/Busines/EventDraft/RadioButton.vue";
import InputWrapper from "@/components/Busines/EventDraft/InputWrapper.vue";
import CategorySelect from "@/components/Busines/EventDraft/CategorySelect.vue";
import ChoiseTime from "@/components/Busines/EventDraft/ChoiseTimeCheckBox.vue";
import LocationWrapperBox from "@/components/Busines/NewEvent/LocationWrapperBox.vue";
import InputTextCountry from "@/components/Busines/EventDraft/InputTextCountry.vue";
import InputTextCity from "@/components/Busines/EventDraft/InputTextCity.vue";
export default {
    components: {
        InputTextCity,
        InputTextCountry,
        LocationWrapperBox,
        ChoiseTime,
        InputTextAddress,
        NumberPicker,
        DropSelect,
        PickerDate,
        ErrorFieldText,
        ImagesLoad,
        InputText,
        AddList,
        InputTextArea,
        RadioButton,
        InputWrapper,
        CategorySelect
    },
    setup: () => ({ v$: useVuelidate() }),
    name: "tab-content",
    props: {
        data: Object,
        step: Number,
        error_data: Array,
    },
    data() {
        return {
            id_page: this.$route.params.id,
            current_state: [],
            datePickerType: 1,
            submitted: false,
            allSelectMonthCheck: false,
            allSelectMonthCheckRight: false,
            newEventData: [],
            newEvent: [],
            ageRestrictions: [
                { title: 'None', value: 1 },
                { title: 'Without children', value: 2 },
                { title: 'Child (max 3 year)', value: 3 },
                { title: 'Event 18+', value: 4 },
            ],
            confirmationType: [
                { title: 'Manually', value: 1 },
                { title: 'Automatically', value: 2 },
            ],
            mapeaningData: [
                { title: 'Hours', value: 1 },
                { title: 'Days', value: 2 },
                { title: 'Weeks', value: 3 },
            ],
            paymentMethodData: [
                { title: 'Online', value: 1 },
                { title: 'On the event day', value: 2 },
                { title: 'In office', value: 3 },
            ],
            currencyData: [
                { title: '$ USD', value: 1 },
                { title: '$ EUR', value: 2 },
            ],
            conditionsData: [
                { title: 'per person', value: 1 },
                { title: 'per person', value: 2 },
            ],
            serviceData: [
                { title: 'Experience', value: 1 },
                { title: 'Event', value: 2 },
            ],
            meeting_point: [
                { title: 'Transfer', value: 1 },
                { title: 'Location', value: 2 },
            ],
            timeData: this.$store.state.timeData,
            paymentsData: [
                { title: 'Free', value: 1 },
                { title: 'Paid', value: 2 },
            ],
            languegesData: [],
            categoryData: [],
            categoryDataSearch: [],
        }
    },
    validations() {
        return {
            newEvent: {
                type_event: { required },
                charged_to_attend: { required },
                selectedCategory: { required },
                event_name: { required },
                overview: { required },
                included: { required },
                not_included: { required },
                additional_information: { required },
                meeting_point_type: { required },
                meeting_point_country: { required },
                meeting_point_city: { required },
                meeting_point_address: { required },
                meeting_point_building_number: { required },
                cancelation_policy: { required },
                images: { required },
                city_id: { required },
                currency_id: { required },
                price: { required },
                conditions: { required },
                datePicker: { required },
                choose_time: { required },
                age_restrictions: { required },
                max_participants: { required },
                location: { required },
                confirmation_type: { required },
                payment_method: { required },
                estimated_duration: { required },
                estimated_duration_type: { required },
                live_tour_guide: { required },
            }
        }
    },
    methods: {
        saveDraft() {
            let fData = new FormData();
            for (let [key, value] of Object.entries(this.newEvent)) {
                if (key == "images") {
                    value.forEach((fileObj, index) => {
                        fData.append('images[]', fileObj.file);
                    });
                }
                else if ((Array.isArray(value) || typeof value === "object") && value != "") fData.append(key, JSON.stringify(value));
                else if (value != "") fData.append(key, value);
            }
            fData.append('id', this.id_page);
            axios.post("/business/events/draft/updateDraft", fData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }).then(resp => {
                    setTimeout(() => {
                        this.$router.push({ name: "BusinesEvents", query: { tab: 2 } });
                    }, 200);
                }).catch(() => { }).finally(() => { });
        },
        showPreview() {
            let fData = new FormData();
            for (let [key, value] of Object.entries(this.newEvent)) {
                if (key == "images") {
                    value.forEach((fileObj, index) => {
                        fData.append('images[]', fileObj.file);
                    });
                }
                else if ((Array.isArray(value) || typeof value === "object") && value != "") fData.append(key, JSON.stringify(value));
                else if (value != "") fData.append(key, value);
            }
            fData.append('id', this.id_page);
            axios.post("/business/events/draft/updateDraft", fData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }).then(resp => {
                    setTimeout(() => {
                        this.$router.push({ name: "BusinesNewEventPreview", params: { id: resp.data.event_id } });
                    }, 200);
                }).catch(() => { }).finally(() => { });
        },
        otherCategory(value) {
            this.categoryData.push(value);
        },
        uploadFiles(files) {
            this.newEvent.images = files;
        },
        uploadFilesUrl(files) {
            this.newEvent.images_url = files;
        },
        validateFieldsMounted(index) {
            switch (index) {
                case 0:
                    return !this.v$.newEvent.type_event.$invalid
                        && !this.v$.newEvent.charged_to_attend.$invalid
                        && !this.v$.newEvent.selectedCategory.$invalid
                case 1:
                    return !this.v$.newEvent.event_name.$invalid
                        && !this.v$.newEvent.overview.$invalid
                        && !this.v$.newEvent.included.$invalid
                        && !this.v$.newEvent.not_included.$invalid
                        && !this.v$.newEvent.additional_information.$invalid
                        && !this.v$.newEvent.meeting_point_type.$invalid
                        && !this.v$.newEvent.cancelation_policy.$invalid
                case 2:
                    return this.newEvent.charged_to_attend === 2
                        ? !this.v$.newEvent.images.$invalid
                        && !this.v$.newEvent.currency_id.$invalid
                        && !this.v$.newEvent.price.$invalid
                        && !this.v$.newEvent.conditions.$invalid
                        : !this.v$.newEvent.images.$invalid;
                case 3:
                    return !this.v$.newEvent.datePicker.$invalid
                case 4:
                    return !this.v$.newEvent.choose_time.$invalid
                case 5:
                    return !this.v$.newEvent.location.$invalid
                        && !this.v$.newEvent.age_restrictions.$invalid
                        && !this.v$.newEvent.max_participants.$invalid
                        && !this.v$.newEvent.confirmation_type.$invalid
                        && !this.v$.newEvent.payment_method.$invalid
                        && !this.v$.newEvent.estimated_duration.$invalid
                        && !this.v$.newEvent.estimated_duration_type.$invalid
                        && !this.v$.newEvent.live_tour_guide.$invalid
                default:
                    return false;
            }
        },
        validateFields() {
            switch (this.step) {
                case 0:
                    return !this.v$.newEvent.type_event.$invalid
                        && !this.v$.newEvent.charged_to_attend.$invalid
                        && !this.v$.newEvent.selectedCategory.$invalid
                case 1:
                    return !this.v$.newEvent.event_name.$invalid
                        && !this.v$.newEvent.overview.$invalid
                        && !this.v$.newEvent.included.$invalid
                        && !this.v$.newEvent.not_included.$invalid
                        && !this.v$.newEvent.additional_information.$invalid
                        && !this.v$.newEvent.meeting_point_type.$invalid
                        && !this.v$.newEvent.cancelation_policy.$invalid
                case 2:
                    return this.newEvent.charged_to_attend === 2
                        ? !this.v$.newEvent.images.$invalid
                        && !this.v$.newEvent.currency_id.$invalid
                        && !this.v$.newEvent.price.$invalid
                        && !this.v$.newEvent.conditions.$invalid
                        : !this.v$.newEvent.images.$invalid;
                case 3:
                    return !this.v$.newEvent.datePicker.$invalid
                case 4:
                    return !this.v$.newEvent.choose_time.$invalid
                case 5:
                    return !this.v$.newEvent.location.$invalid
                        && !this.v$.newEvent.age_restrictions.$invalid
                        && !this.v$.newEvent.max_participants.$invalid
                        && !this.v$.newEvent.confirmation_type.$invalid
                        && !this.v$.newEvent.payment_method.$invalid
                        && !this.v$.newEvent.estimated_duration.$invalid
                        && !this.v$.newEvent.estimated_duration_type.$invalid
                        && !this.v$.newEvent.live_tour_guide.$invalid
                default:
                    return false;
            }
        },
        nextStep() {
            console.log(this.step);
            if (this.validateFields()) {
                let count = this.step;
                this.submitted = false;
                this.step < 5 ? this.$emit('step', count += 1) : false;
            } else {
                this.submitted = true;
                console.log("Validation error");
            }
        },
        nextPreview() {
            if (this.validateFields()) {
                let count = this.step;
                this.submitted = false;
                this.showPreview();
            } else {
                this.submitted = true;
                console.log("Validation error");
            }
        },
        nextFinish() {
            this.saveDraft();
            let fData = new FormData();
            fData.append("id", this.id_page);
            axios.post("/business/new-event/finish", fData).then(resp => {
                if (resp.data.result == true) {
                    this.$router.push({ name: "BusinesEvents" });
                }
            }).catch(() => {
                this.$router.push({ name: "BusinesEvents" });
            }).finally(() => {

            });
        },
        previousStep() {
            let count = this.step;
            this.$emit('step', Math.max(0, count - 1))
        },
        getInit() {
            axios.get("/business/new-event/init").then(resp => {
                if (resp.data['result'] == true) {
                    this.currencyData = resp.data.currency;
                    this.languegesData = resp.data.langueges;
                }
            }).catch(() => { }).finally(() => { });
        },
        getCategory(value) {

            let fData = new FormData();
            fData.append("type", value);
            axios.post("/business/new-event/get-category", fData).then(resp => {
                if (resp.data['result'] == true) {
                    this.categoryData = resp.data.category;
                    this.categoryDataSearch = resp.data.categorySearch;
                }
            }).catch(() => { }).finally(() => { });
        }
    },
    mounted() {
        this.getInit();
        for (let i = 0; i < 6; i++) {
            this.current_state.push(this.validateFieldsMounted(i));
        }
    },
    watch: {
        'newEvent.type_event': {
            handler(val, oldVal) {
                this.getCategory(val);
            },
            deep: true
        },
        newEvent: {
            handler(val, oldVal) {
                this.$emit('arrData', this.newEvent);
            },
            deep: true
        },
        data: {
            handler(newData) {

                this.newEventData = { ...newData };
                if (newData.end_date != null) {
                    this.datePickerType = 2;
                }
            },
            deep: true,
            immediate: true,
        },
    },
    computed: {
        meetingPointError() {
        const { meeting_point_type, meeting_point_country, meeting_point_city, meeting_point_address, meeting_point_building_number } = this.v$.newEvent;

        // Проверка для type 2 (Location)
        if (meeting_point_type.$model === 2) {
            return (
            meeting_point_country.$invalid ||
            meeting_point_city.$invalid ||
            meeting_point_address.$invalid ||
            meeting_point_building_number.$invalid
            );
        }

        // Проверка для type 1 (Hotel Transfers)
        if (meeting_point_type.$model === 1) {
            return this.v$.newEvent.transfers.$invalid;
        }

        // Ошибка, если не выбран type
        return meeting_point_type.$invalid;
        },
    },
}
</script>

<style scoped>
.half-width {
    flex: 1 1 calc(50% - 16px); /* элементы занимают половину ширины контейнера с отступами */
}
.full-width {
    flex: 1 1 calc(69% - 16px); /* элемент занимает всю ширину контейнера */
}
.low-width{
    flex: 1 1 calc(31% - 16px);
}

#other_month {
    display: none;
}

.picker-option {
    margin-bottom: 16px;
}

.picker-option button {
    width: 210px;
    height: 36px;
}

.wrapper-content {
    height: 100%;
    width: 100%;
    padding: 24px;
    border-radius: 16px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    background: #FFF;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
}

.wrapper-content .content {
    display: none;
    flex-direction: column;
    gap: 40px;
    max-width: 764px;
}

.content.max-width {
    max-width: 100%;
}

.steps .progress {
    display: none;
    width: 100%;
}

.steps .progress div {
    border-radius: 5px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    height: 10px;
    border-radius: 6px;
    width: 100%;
    background: #fff;
}

.steps .progress div.current {
    border-radius: 5px;
    border: 1.5px solid var(--Green-400, #39766A);
}

.steps .progress div.active {
    border-radius: 5px;
    border: 1.5px solid var(--Green-400, #39766A);
    background: var(--Green-400, #39766A);
}

.steps .button-wrapper {
    padding-top: 67px;
    display: flex;
    justify-content: space-between;
}

.steps .left div,
.steps .right div {
    width: 120px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;

    color: var(--Black-400, #292929);
    font-family: Standerd;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.32px;
    cursor: pointer;
}

.steps .left div,
.steps .right .next {
    border-radius: 8px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    background: var(--White-100, #FFF);
}

.steps .right {
    display: flex;
    gap: 24px;
}

.flex-wrapper {
    display: flex;
    gap: 168px;
}

.flex-wrapper>div {
    width: 100%;
}

.flex-wrapper>div .title {
    color: var(--Black-400, #292929);
    font-family: Standerd;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    /* 23.8px */
    letter-spacing: 0.102px;
    padding-bottom: 8px;
}

.price-wrapper {
    display: flex;
    gap: 16px;
    max-width: 498px;
}

.price-wrapper .title {
    color: var(--Black-400, #292929);
    font-family: Standerd;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    /* Field not filled in.077% */
    margin-bottom: 8px;
}

.duratio-wrapper .title {
    color: var(--Black-400, #292929);
    font-family: Standerd;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    /* Field not filled in.077% */
    margin-bottom: 8px;
}

.duratio-wrapper .time {
    width: 100%;
    max-width: 200px;
}

.duratio-wrapper .meaning {
    width: 100%;
    max-width: 164px;
}

.duratio-wrapper {
    display: flex;
    gap: 16px;
}

.wrapper-column {
    display: flex;
    gap: 20px;
    flex-direction: column;
}

.wrapper-content .content.show {
    display: flex;
}

.activities-take {
    border-radius: 8px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    padding-left: 16px;
    width: 100%;
    height: 36px;
    display: flex;
    gap: 10px;
    align-items: center;
    padding-left: 12px;
    height: 50px;
}

.activity-wrapper {
    position: relative;

}

.activity-wrapper .drop-down {
    position: absolute;
    top: 113%;
    left: 0;
    width: 100%;
    overflow-y: auto;
    max-height: 200px;
    border-radius: 8px;
    border: 1.5px solid var(--Gray-stroke, #E5E5EA);
    background: #FFF;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.06);
    padding: 8px;
}

.activity-wrapper .drop-down div {
    padding: 0 8px;
    height: 40px;
    display: flex;
    align-items: center;

    color: #232323;
    font-family: Standerd;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 133.333% */
}

.activities-take.error {
    border-radius: 8px;
    border: 1.5px solid var(--Red-200, #EA2313);
}

.input-style-none {
    border-radius: unset;
    border: unset;
    padding-left: unset;
    height: unset;
}

.activity-wrapper input {
    width: 100%;
    height: 100%;
    color: var(--Gray-300, #888);
    font-family: Standerd;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    outline: none;
}

.all-month {
    color: var(--Black-400, #292929);
    text-align: center;
    font-family: Standerd;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 125% */
    display: none !important;
    gap: 8px;
    margin-top: 26px;
    display: inline-flex;
    align-items: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.all-month::before {
    content: '';
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 4px;
    border: 2px solid #E5E5EA;
    width: 20px;
    height: 20px;
    transition: 0.3s;
    background-image: url("@/assets/image/check.svg");
}

.all-month.active {
    display: flex !important;
}

.all-month:hover::before {
    border-radius: 4px;
    border: 2px solid #606060;
}

#other_month:checked+.all-month::before {
    border-color: #292929;
    background-color: #292929;

}
.picker-option {
    max-width: -moz-max-content !important;
    max-width: max-content !important;
}
@media screen and (max-width: 1024px) {
    .steps .progress {
        display: flex;
    }
    .picker-option {
        max-width: unset !important;
    }
    .picker-option button {
        width: 100%;
    }
    .steps .button-wrapper {
        padding-top: 0;
    }

    .steps {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        background: #FFF;
    }

    .steps .mobile-none {
        display: none !important;
    }

    .steps .left div,
    .steps .right .next {
        width: 100%;
        height: 50px;
        border: unset;
        border-radius: unset;
    }

    .steps .left div {
        border: 1.5px solid var(--Gray-stroke, #E5E5EA);
        background: var(--Gray-stroke, #E5E5EA);
    }

    .steps .left,
    .steps .right {
        width: 100%;
    }

    .all-month {
        padding-left: 16px;
        margin-top: 16px;
        margin-bottom: 22px;
    }
}

@media screen and (max-width: 768px) {
    .wrapper-content {
        padding: 16px;
    }

    .flex-wrapper {
        flex-direction: column;
        gap: 16px;
    }

    .price-wrapper {
        flex-wrap: wrap;
    }
}
</style>