<template>
  <header-page></header-page>
  <main class="event">
    <bread-crumb class="mobile-none" :exit="false" :loading="loadingСompleted" :post="arr_data"></bread-crumb>
    <event-content>
      <ViewImages class="mobile-none-images preview" v-if="loadingСompleted" :all_photo="showAllPhoto" @all-photo="showAllPhoto = $event"
                  :images="arr_data[0].images" :loading="loadingСompleted" :company_id="arr_data[0].company_id"
                  :id="arr_data[0].id"></ViewImages>
      <ViewPhotosSlider class="mobile-images" v-if="loadingСompleted" :id="arr_data[0].id" :company_id="arr_data[0].company_id" :images="arr_data[0].images"></ViewPhotosSlider>
      <ContentPage> 
        <template v-slot:left>
          <Block :loading="loadingСompleted">
            <template v-slot:left>
              <GeneralInformation v-if="loadingСompleted" :estimated_duration="arr_data[0].estimated_duration"
                :estimated_duration_type="arr_data[0].estimated_duration_type"
                :meeting_point_type="arr_data[0].meeting_point_type" :max_participants="arr_data[0].max_participants"
                :languages_name="arr_data[0].languages_name"></GeneralInformation>
                <ErrorFixBlock v-if="loadingСompleted && arr_data[0].verification != null" :value="arr_data[0].verification[0]"></ErrorFixBlock>
            </template>
            <template v-if="loadingСompleted && arr_data[0].verification != null" v-slot:right>
              <ErrorFixBlock v-if="loadingСompleted" :value="arr_data[0].verification[0]"></ErrorFixBlock>
            </template>
          </Block>
          <Block :loading="loadingСompleted">
            <template v-slot:left>
              <BlockDescription v-if="loadingСompleted" :title="'Overview'" :value="arr_data[0].overview">
              </BlockDescription>
              <ErrorFixBlock v-if="loadingСompleted && arr_data[0].verification != null" :value="arr_data[0].verification[1]"></ErrorFixBlock>
            </template>
            <template v-if="loadingСompleted && arr_data[0].verification != null" v-slot:right>
              <ErrorFixBlock v-if="loadingСompleted" :value="arr_data[0].verification[1]"></ErrorFixBlock>
            </template>
          </Block>
          <Block :loading="loadingСompleted">
            <template v-slot:left>
              <BlockList v-if="loadingСompleted" :title="'Additional Information'"
                :arrayList="arr_data[0].additional_information"></BlockList>
              <ErrorFixBlock v-if="loadingСompleted && arr_data[0].verification != null" :value="arr_data[0].verification[2]"></ErrorFixBlock>
            </template>
            <template v-if="loadingСompleted && arr_data[0].verification != null" v-slot:right>
              <ErrorFixBlock v-if="loadingСompleted" :value="arr_data[0].verification[2]"></ErrorFixBlock>
            </template>
          </Block>
          <Block :loading="loadingСompleted">
            <template v-slot:left>
              <BlockDescription v-if="loadingСompleted" :title="'Meeting point'" :value="arr_data[0].meeting_point">
              </BlockDescription>
              <ErrorFixBlock v-if="loadingСompleted && arr_data[0].verification != null" :value="arr_data[0].verification[3]"></ErrorFixBlock>
            </template>
            <template v-if="loadingСompleted && arr_data[0].verification != null" v-slot:right>
              <ErrorFixBlock v-if="loadingСompleted" :value="arr_data[0].verification[3]"></ErrorFixBlock>
            </template>
          </Block>
          <Block :loading="loadingСompleted">
            <template v-slot:left>
              <BlockList v-if="loadingСompleted" :title="'Cancelation policy'"
                :arrayList="arr_data[0].cancelation_policy"></BlockList>
              <ErrorFixBlock v-if="loadingСompleted && arr_data[0].verification != null" :value="arr_data[0].verification[4]"></ErrorFixBlock>
            </template>
            <template v-if="loadingСompleted && arr_data[0].verification != null" v-slot:right>
              <ErrorFixBlock v-if="loadingСompleted" :value="arr_data[0].verification[4]"></ErrorFixBlock>
            </template>
          </Block>
        </template>
      </ContentPage>
    </event-content>
    <ConfirmationBlock>
      <Button class="white" @click="rejectEvent">Back</Button>
      <Button class="green" @click="approveEvent">Finish</Button>
    </ConfirmationBlock>
  </main>
</template>

<script>
import axios from "axios";
import ViewPhotosSlider from "@/components/EventPage/ViewPhotosSlider.vue";
import BreadCrumb from "@/components/BreadCrumb.vue";
import EventContent from "@/components/Busines/NewEventPreview/EventContent.vue";
import ViewImages from "@/components/EventPage/ViewPhotos.vue";
import ViewAllPhotos from "@/components/EventPage/ViewAllPhotos.vue";
import ConfirmationBlock from "@/components/EventPage/ConfirmationBlock.vue";
import ContentPage from "@/components/EventPage/ContentPage/ContentPage.vue";
import Block from "@/components/EventPage/ContentPage/BlockWrapper.vue";
import GeneralInformation from "@/components/EventPage/ContentPage/GeneralInformation.vue";
import BlockList from "@/components/EventPage/ContentPage/BlockList.vue";
import BlockDescription from "@/components/EventPage/ContentPage/BlockDescription.vue";
import ErrorFixBlock from "@/components/EventPage/ContentPage/ErrorFixBlock.vue";
import HeaderBusines from "@/components/Busines/HeaderBusines.vue";
export default {
  components: { ViewPhotosSlider,HeaderBusines,ErrorFixBlock, BreadCrumb, EventContent, ViewImages, ViewAllPhotos, ContentPage, Block, GeneralInformation, BlockList, BlockDescription, ConfirmationBlock },
  data() {
    return {
      showAllPhoto: false,
      loadingСompleted: false,
      id_page: this.$route.params.id,
      arr_data: [],
      windowWidth: window.innerWidth
    }
  },
  methods: {
    rejectEvent() {
      this.$router.push({ name: 'BusinesDraftEvent', params: { id: this.id_page } });
    },
    getData() {
      this.loadingСompleted = false;
      let fData = new FormData();
      fData.append("id", this.id_page);
      axios.post("/business/new-event/preview", fData).then(resp => {
        if (resp.data.result == true) {
          this.loadingСompleted = true;
          this.arr_data = resp.data.data;
        }
        else {
          this.$router.push({ name: "BusinesDashboard" });
        }
      }).catch(() => {
        this.$router.push({ name: "BusinesDashboard" });
      }).finally(() => {

      });
    },
    approveEvent() {
      let fData = new FormData();
      fData.append("id", this.id_page);
      axios.post("/business/new-event/finish", fData).then(resp => {
        if (resp.data.result == true) {
          this.$router.push({ name: "BusinesEvents" });
        }
      }).catch(() => {
        this.$router.push({ name: "BusinesEvents" });
      }).finally(() => {

      });
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    }
  },
  mounted() {
    this.getData();
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
}
</script>

<style scoped>
.mobile-768 {
  display: none;
}
.mobile{
  display: none;
}
.mobile-images{
  display: none;
}
.event {
  margin-bottom: 197px;
}
@media screen and (max-width: 1024px) {
  .mobile-none {
    display: none;
  }
  .mobile{
    display: block;
  }
}
@media screen and (max-width: 768px) {
  .mobile-none-images {
    display: none;
  }
  .mobile-images{
    display: block;
  }
}
</style>